import React, { Component } from "react";
import "./Cv.css";
import { Firebase_Config } from "../../../Config/config";
import * as firebase from "firebase/app";
import "firebase/database";

import orateur1 from "../../../assets/orateurs/Portrait_Homme.png"
import orateur2 from "../../../assets/orateurs/Portrait_Homme.png"
import orateur3 from "../../../assets/orateurs/Portrait_Femme.png"
import orateur4 from "../../../assets/orateurs/Portrait_Homme.png"
import orateur5 from "../../../assets/orateurs/Portrait_Femme.png"
import orateur6 from "../../../assets/orateurs/Portrait_Femme.png"
import orateur7 from "../../../assets/orateurs/Portrait_Femme.png"

class Cv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      etat: false,
      envoye: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }
  }
  componentWillMount() {
    this.db = firebase.database();
  }
  componentDidMount() { }
  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    if (this.state.value !== "") {
      let uid = firebase.database().ref().child("questionsMSDJ2I").push().key;
      let uid2 = firebase.database().ref().child("questionsMSDJ2I").push().key;

      let d = new Date();
      let n = d.toLocaleString();
      let data = {
        id: uid,
        question: this.state.value,
        date: n,
      };

      let updates = {};
      let updates2 = {};

      updates["/questionsMSDJ2I/" + uid] = data;
      firebase
        .database()
        .ref()
        .update(updates)
        .then(
          this.setState({
            etat: false,
            value: "",
            envoye: true,
          }),
          setTimeout(() => {
            this.setState({
              envoye: false,
            });
          }, 5000)
        );

      updates2["/questionsMSDJ2ISave/" + uid] = data;
      firebase.database().ref().update(updates2);

      event.preventDefault();
    }
  }

  render() {
    return (
      <div className="CvContainer overflow" style={{ overflow: "auto", paddingTop: 0, height: "98%" }}>
        <div>
          <div className="orateurBlock orateurImage">
            <img src={orateur1} />
          </div>
          <div className="orateurBlock nameDescOrateur">
            <strong>Pr Sharam Attarian,</strong><span className="descOrateur"><i> Neurologue APHM - La Timone</i></span><br />
            <span className="cvOrateur">
              Chef de service des Maladies neuromusculaires et la sclérose latérale amyotrophique de l’hôpital de la Timone.
              Il est aussi le Coordinateur de la filière FILNEMUS et coordinateur des Centres de Référence de Maladies&nbsp;Neuromusculaires PACA&#8209;Rhône&#8209;Alpes.
            </span>
          </div>
        </div>

        <div>
          <div className="orateurBlock orateurImage">
            <img src={orateur2} />
          </div>
          <div className="orateurBlock nameDescOrateur">
            <strong>Pr Robert Carlier,</strong><span className="descOrateur"><i> Radiologue APHP - Raymond-Poincaré</i></span>
            <span className="cvOrateur">
              Radiologue au service d’Imagerie médicale diagnostique et interventionnelle de l’hôpital Raymond&#8209;Poincaré de Garches.
            </span>
          </div>
        </div>

        <div>
          <div className="orateurBlock orateurImage">
            <img src={orateur3} />
          </div>
          <div className="orateurBlock nameDescOrateur">
            <strong>Mme Marie Carmen Cruz,</strong><span className="descOrateur"><i> IDE CHU Toulouse</i></span>
            <span className="cvOrateur">
              Infirmière référente du centre de référence des maladies neuromusculaires au CHU&nbsp;de&nbsp;Toulouse.
            </span>
          </div>
        </div>

        <div>
          <div className="orateurBlock orateurImage">
            <img src={orateur4} />
          </div>
          <div className="orateurBlock nameDescOrateur">
            <strong>Dr Pascal Cintas,</strong><span className="descOrateur"><i> Neurologue CHU Toulouse</i></span>
            <span className="cvOrateur">
              Neurologue au CHU&nbsp;de&nbsp;Toulouse, coordinateur du Centre de référence de la sclérose latérale amyotrophique et autres maladies rares du neurone moteur,
              ainsi que du Centre de référence des maladies neuromusculaires adultes.
            </span>
          </div>
        </div>

        <div>
          <div className="orateurBlock orateurImage">
            <img src={orateur5} />
          </div>
          <div className="orateurBlock nameDescOrateur">
            <strong>Dr Emmanuelle Salort-Campana,</strong><span className="descOrateur"><i> Neurologue APHM - La Timone</i></span>
            <span className="cvOrateur">
              Neurologue au sein du Centre de Référence de Maladies Neuromusculaires PACA&#8209;Rhône&#8209;Alpes.
            </span>
          </div>
        </div>

        <div>
          <div className="orateurBlock orateurImage">
            <img src={orateur6} />
          </div>
          <div className="orateurBlock nameDescOrateur">
            <strong>Dr Tanya Stojkovic,</strong><span className="descOrateur"><i> Neurologue APHP - La Pitié-Salpêtrière</i></span>
            <span className="cvOrateur">
              Neurologue à l’hôpital de la Pitié&#8209;Salpêtrière, elle est spécialiste des maladies neuromusculaires, au sein du service de Neuro&#8209;Myologie à l’Institut de Myologie.
            </span>
          </div>
        </div>

        <div>
          <div className="orateurBlock orateurImage">
            <img src={orateur7} />
          </div>
          <div className="orateurBlock nameDescOrateur">
            <strong>Pr Anna Kostera-Pruszczyk,</strong><span className="descOrateur"><i> Neurologue UCK Varsovie, Pologne</i></span>
            <span className="cvOrateur">
              Neurologue dirigeant le département et la clinique de neurologie de l'UCK WUM.
              Membre du conseil du centre d'excellence pour les maladies rares et non diagnostiquées de l'université de médecine de Varsovie et dirige également le centre Euro&#8209;NMD.
              Elle est aussi la vice&#8209;présidente du Conseil des Maladies Rares du ministère de la santé Polonais.
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Cv;
