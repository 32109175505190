import React, { Component } from "react";
import "./InscriptionFormulaire.css";
import axios from "axios";
import loading from "../../assets/loading.png";
import { Firebase_Config } from "../../Config/config";
import * as firebase from "firebase/app";
import "firebase/database";

import arrowD from "../../assets/Fleche_MenuDeroulant_ON.png";
import arrowR from "../../assets/Fleche_MenuDeroulant_OFF.png";

import inscris from "../../assets/BTN_jeminscris.png"

import backgroundInteresse from "../../assets/FD_popup.png"
import exitInteresse from "../../assets/Croix.png"
import valideInteresse from "../../assets/BTN_valider.png"

class InscriptionFormulaire extends Component {
  constructor(props, context) {
    super(props, context);
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }

    this.state = {
      nom: "",
      prenom: "",
      email: "",
      centre: "",
      sessions: "",
      rpps: "",
      specialite: "",
      messages: "",
      confirmation: "",
      certifie: false,

      errors: {},
      popup: false,
      sended: false,
      valid: false,
      statusToken: "",
      validation: false,
      player: 0,
      confirmation: false,

      sessionTout: true,
      sessionEpidemio: false,
      sessionRestore: false,
      sessionTableRonde: false,
      sessionCoeur: false,
      boutonWidth: 150,

      boutonOpen: false,

      popupInteresse: false,
      popupInteresseSend: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitInteresse = this.handleSubmitInteresse.bind(this);
    this.handleChangesessions = this.handleChangesessions.bind(this);
    this.handleChangeRpps = this.handleChangeRpps.bind(this);
    this.handleChangeSpe = this.handleChangeSpe.bind(this);
    this.handleChangeNom = this.handleChangeNom.bind(this);
    this.handleChangePrenom = this.handleChangePrenom.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeCentre = this.handleChangeCentre.bind(this);
    this.handleChangeRadioToTrue = this.handleChangeRadioToTrue.bind(this);
    this.handleChangeRadioToFalse = this.handleChangeRadioToFalse.bind(this);
    this.handleChangeRadioCertifie = this.handleChangeRadioCertifie.bind(this);

    this.onChangeAll = this.onChangeAll.bind(this);
    this.onChangeEpidemio = this.onChangeEpidemio.bind(this);
    this.onChangeRestore = this.onChangeRestore.bind(this);
    this.onChangeTableRonde = this.onChangeTableRonde.bind(this);
    this.onChangeCoeur = this.onChangeCoeur.bind(this);
    this.onBouton = this.onBouton.bind(this);

    this.handleValidation = this.handleValidation.bind(this);
    this.setAlert = this.props.alert;
    this.interval = -1;

    //this.boutonRef = React.createRef();
  }

  componentWillMount() {
    this.db = firebase.database();
    this.db.ref("PlayerMSDJ2I").on("value", (snap) => {
      this.setState({
        player: snap.val(),
      });

      {
        /**  valid: true,
        validation: true,
        confirmation: true, */
      }
      this.setState({});
    });

    if (this.props.token === "interesse") {
      this.setState({popupInteresse:true})
    }
    else this.getUserbyToken();
  }
  componentDidMount() { }

  popupInteresse = () => {
    var body = document.body;
    var html = document.documentElement;
    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    var width = document.documentElement.clientWidth;
    if (this.state.popupInteresse) {
      return (
        <div
          className="popup"
          style={{
            width: width,
            height: height,
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "999",
          }}
        >
          <div className="BlockPopup2" style={{ position: "relative" }}>
            <img style={{ width: "100%" }} src={backgroundInteresse} />
            <div onClick={() => this.setState({ popupInteresse: false })} className="cliquable exitInteresse" style={{ position: "absolute", top: 20, right: 20, zIndex: "9999" }}>
              <img style={{width:"100%"}} src={exitInteresse} />
            </div>
            {this.state.popupInteresseSend ? <div style={{ position: "absolute", top: 20, left: 20, width: "100%", fontSize: 30 }}>
              <div className="interesseBlock interesseBlock2" style={{ width: "80%", marginLeft: 'auto', marginRight: 'auto', textAlign: "start", marginTop: "20%" }}>
                Merci, votre demande a bien été prise en compte.
              </div>
            </div> : <div>
              <div className="interesseBlock2" style={{ position: "absolute", top: 20, left: 20, width: "100%", fontSize: 30 }}>
                <div className="interesseBlock" style={{ width: "80%", marginLeft: 'auto', marginRight: 'auto', textAlign: "start", marginTop: 80 }}>
                  En complétant ce champ vous acceptez de recevoir de
                  la part de Biogen une communication post événement.

                  <div>
                    <input
                      type="email"
                      id="emailInteresse"
                      style={{ backgroundColor: "transparent", color: "white", width: "80%", marginTop: 25 }}
                      name="email"
                      placeholder="Email*"
                      required
                      value={this.state.email}
                      onChange={this.handleChangeEmail}
                    />
                    {this.state.errors["email"]}
                  </div>

                  <div style={{ marginTop: 25 }} className="btnValider2" onClick={this.handleSubmitInteresse}>
                    <img
                      src={valideInteresse}
                      alt="btn valider"
                    />
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      )
    }
  }

  handleValidation() {
    //let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name

    if (this.state.nom === "") {
      formIsValid = false;
      this.setAlert("Veuillez remplir le champs Nom");
    }

    if (this.state.prenom === "") {
      formIsValid = false;
      this.setAlert("Veuillez remplir le champs Prénom");
    }

    if (this.state.nom !== "undefined") {
      if (
        this.state.nom.length > 0 &&
        !this.state.nom.match(/^[a-zA-Z\- ']+$/)
      ) {
        formIsValid = false;
        this.setAlert("Le champs Nom ne peut contenir que des lettres");
      }
    }
    if (this.state.prenom !== "undefined") {
      if (
        this.state.prenom.length > 0 &&
        !this.state.prenom.match(/^[a-zA-Z\- ']+$/)
      ) {
        formIsValid = false;
        this.setAlert("Le champs Prénom ne peut contenir que des lettres");
      }
      if (this.state.rpps !== "undefined") {
        if (this.state.rpps.length > 0 && !this.state.rpps.match(/^[0-9]+$/)) {
          formIsValid = false;
          this.setAlert("Le champs RPPS ne peut contenir que des chiffres");
        }
      }
    }

    //Email
    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    }

    if (this.state.email !== "undefined") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmitInteresse(event) {
    let lastAtPos = this.state.email.lastIndexOf("@");
    let lastDotPos = this.state.email.lastIndexOf(".");

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        this.state.email.indexOf("@@") === -1 &&
        lastDotPos > 2 &&
        this.state.email.length - lastDotPos > 2
      )
    ) {
      this.setAlert("Veuillez saisir une adresse mail valide");
    } else {
      this.setState({
        popupInteresseSend: true,
      });

      this.InscriptionInteresse();
    }
    event.preventDefault();
  }

  handleSubmit(event) {
    let lastAtPos = this.state.email.lastIndexOf("@");
    let lastDotPos = this.state.email.lastIndexOf(".");

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        this.state.email.indexOf("@@") === -1 &&
        lastDotPos > 2 &&
        this.state.email.length - lastDotPos > 2
      )
    ) {
      this.setAlert("Veuillez saisir une adresse mail valide");
    } else if (this.state.certifie === false) {
      this.setAlert(
        "En cochant cette case, vous acceptez la politique RGPD de Biogen."
      );
    } else {
      this.setState({
        sended: true,
        popup: true,
      });

      this.Inscription();
    }

    event.preventDefault();
  }

  handleChangeRadioToTrue(event) {
    this.setState({
      confirmation: true,
    });
  }

  handleChangeRadioToFalse(event) {
    this.setState({
      confirmation: false,
    });
  }

  handleChangeRadioCertifie(event) {
    this.setState({
      certifie: true,
    });
  }

  handleChangeRpps(event) {
    this.setState({ rpps: event.target.value });
  }

  handleChangeSpe(event) {
    this.setState({ specialite: event.target.value });
  }

  handleChangesessions(event) {
    this.setState({ sessions: event.target.value });
  }

  handleChangeNom(event) {
    this.setState({ nom: event.target.value });
  }

  handleChangePrenom(event) {
    this.setState({ prenom: event.target.value });
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleChangeCentre(event) {
    this.setState({ centre: event.target.value });
  }

  getUserbyToken = () => {
    setTimeout(() => {
      axios
        .post(
          `https://epsma-biogen.com/api/users/getUserByToken`,
          {
            token: this.props.token,
          } /*,
          {
            headers: {
              "Access-Control-Request-Headers":
                "X-Requested-With,X-Auth-Token,Content-Type,  Content-Length, Authorization",
            },
          }*/
        )
        .then((res) => {
          this.setState({
            nom: res.data.prenom_invite,
            prenom: res.data.nom_invite,
            email: res.data.email_invite,
            centre: res.data.etablissement_invite,
            validation: true,
            statusToken: 200,
          });
        })
        .catch((err) => this.setState({ statusToken: "err" }));
    }, 200);
  };
  ConfirmeInscription = () => {
    //console.log(this.state.confirmation);
    if (this.state.confirmation === true) {
      axios
        .post(`https://epsma-biogen.com/api/users/update`, {
          confirmation: this.state.confirmation,
          email_invite: this.state.email,
        })
        .then((res) => {
          this.setState({
            valid: true,
            validation: true,
          });
        })
        .catch((err) => {
          this.setState({ validation: false });
        });
    } else {
      axios
        .post(`https://epsma-biogen.com/api/users/update`, {
          confirmation: this.state.confirmation,
          email_invite: this.state.email,
        })
        .then((res) => {
          this.setState({
            valid: true,
            validation: this.state.confirmation,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ validation: false });
        });
    }
  };

  InscriptionInteresse() {
    axios
      .post(`https://epsma-biogen.com/api/users/interesse`, {
        email: this.state.email,
      })
      .then((res) => {
      })
      .catch((err) => {
        this.setState({ validation: false, sended: false, popup: false });
        this.setAlert("Une erreur a été rencontrée veuillez essayer à nouveau");
      });
    //console.log("ok");
  }

  Inscription() {
    if (this.handleValidation()) {
      axios
        .post(`https://epsma-biogen.com/api/users/suscribe`, {
          nom: this.state.nom,
          prenom: this.state.prenom,
          email: this.state.email,
          departement: this.state.centre,
          rpps: this.state.rpps,
          specialite: this.state.specialite,
          sessionTout: this.state.sessionTout,
          sessionEpidemio: this.state.sessionEpidemio,
          sessionRestore: this.state.sessionRestore,
          sessionTableRonde: this.state.sessionTableRonde,
          sessionCoeur: this.state.sessionCoeur,
        })
        .then((res) => {
          this.setState({
            valid: true,
            validation: true,
            confirmation: true,
          });
        })
        .catch((err) => {
          this.setState({ validation: false, sended: false, popup: false });
          this.setAlert("Cette adresse email est déjà enregistrée");
        });
      //console.log("ok");
    } else {
      this.setState({
        sended: false,
        popup: false,
      });
    }
  }

  Popup() {
    if (this.state.popup) {
      var body = document.body;
      var html = document.documentElement;
      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      var width = document.documentElement.clientWidth;
      var content = null;
      if (this.state.valid && this.state.confirmation === true) {
        if (this.interval === -1) {
          this.interval = setInterval(() => {
            this.setState({ popup: false });
            clearInterval(this.interval);
            this.interval = -1;
          }, 15000);
        }
        content = (
          <div className="BlockPopup">
            <p className="textPopup">Merci !</p>
            <p className="textPopup">
              Votre inscription à la web-conférence « Échange Pluridisciplinaire pour le suivi
              des patients SMA » a bien été prise en compte et vous allez recevoir un email
              de confirmation.
            </p>
            <p className="textPopup">
              Si vous ne recevez pas l'email de confirmation, veuillez vérifier
              dans votre boîte SPAM ou attendez quelques minutes.
            </p>
            <p className="textPopup">
              Vous allez revenir sur la page d'ici quelques secondes.
            </p>
            <p className="textPopup">À Bientôt !</p>
          </div>
        );
      } else if (this.state.valid && this.state.confirmation === false) {
        if (this.interval === -1) {
          this.interval = setInterval(() => {
            this.setState({ popup: false });
            clearInterval(this.interval);
            this.interval = -1;
          }, 15000);
        }
        content = (
          <div className="BlockPopup">
            <p className="textPopup">Merci.</p>
            <p className="textPopup">
              Votre réponse a bien été prise en compte et vous allez recevoir un
              email de confirmation.
            </p>
            <p className="textPopup">
              Si vous ne recevez pas l'email de confirmation, veuillez vérifier
              dans votre boîte SPAM ou attendez quelques minutes.
            </p>
            <p className="textPopup">
              Vous allez revenir sur la page d'ici quelques secondes.
            </p>
            <p className="textPopup">À Bientôt !</p>
          </div>
        );
      } else {
        content = (
          <div className="BlockPopup">
            <p className="textPopup">Veuillez patienter.</p>
            <p className="textPopup">
              Vous serez inscrit d'ici une dizaine de secondes maximum.
            </p>
            <img src={loading} className="loadingLogo" alt="loadingIndicator" />
          </div>
        );
      }
      return (
        <div
          className="popup"
          style={{
            width: width,
            height: height,
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "10",
          }}
        >
          {content}
        </div>
      );
    }
  }

  onChangeAll(event) {
    // console.log("hello");
    if (this.state.sessionTout) {
      this.setState({
        sessionTout: false,
      });
    } else {
      this.setState({
        sessionTout: true,
        sessionEpidemio: false,
        sessionRestore: false,
        sessionTableRonde: false,
        sessionCoeur: false,
      });
    }
  }

  onChangeEpidemio(event) {
    if (this.state.sessionEpidemio) {
      this.setState({
        sessionEpidemio: false,
      });
    } else {
      this.setState({
        sessionEpidemio: true,
        sessionTout: false,
      });
    }
  }

  onChangeRestore(event) {
    if (this.state.sessionRestore) {
      this.setState({
        sessionRestore: false,
      });
    } else {
      this.setState({
        sessionRestore: true,
        sessionTout: false,
      });
    }
  }

  onChangeEpidemio(event) {
    if (this.state.sessionEpidemio) {
      this.setState({
        sessionEpidemio: false,
      });
    } else {
      this.setState({
        sessionEpidemio: true,
        sessionTout: false,
      });
    }
  }
  onChangeTableRonde(event) {
    if (this.state.sessionTableRonde) {
      this.setState({
        sessionTableRonde: false,
      });
    } else {
      this.setState({
        sessionTableRonde: true,
        sessionTout: false,
      });
    }
  }

  onChangeCoeur(event) {
    if (this.state.sessionCoeur) {
      this.setState({
        sessionCoeur: false,
      });
    } else {
      this.setState({
        sessionCoeur: true,
        sessionTout: false,
      });
    }
  }

  componentDidMount() {
    /*this.setState({
      boutonWidth: this.boutonRef.current.offsetWidth,
    });*/
  }

  onBouton() {
    this.setState({
      boutonOpen: !this.state.boutonOpen,
    });
  }

  render() {
    var urlLive = "https://msdj2i.com/live/#/" + this.props.token;

    var templateVideo = (
      <div>
        <div className="title1" style={{ marginTop: "20px" }}>
          {" "}
          En savoir plus sur le programme
        </div>

        <div className="bottomInscription">

          <div className="react-player ">
            <video
              width="320"
              height="240"
              preload="auto"
              controls
              disablepictureinpicture=""
              controlslist="nodownload"
            >
              <source
                src="https://msdj2i.com/vid/teaser_v1.mp4"
                //src="https://msdj2i.com/vid/TEASER_2.mp4"
                //src="https://msdj2i.com/vid/TEASER_3.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    );

    var templatePlayer = "";
    if (this.state.player === 1) {
      templatePlayer = (
        <div>
          <div className="PlayCadre">
            <a className="linkPlay" href={urlLive} target="_blank">
              <img
                src="https://h8.events.novartis.fr/demo/IMG/play.png"
                alt=""
                className="imgPlay"
              />
            </a>
          </div>

          <div className="titreTeaser">
            {/** <p>Teaser video : Dr Marie MARTIN, rhumatologue</p>*/}
            <p>Cliquez sur le bouton play ci-dessus pour accéder au live.</p>
          </div>
        </div>
      );
    } else {
      templatePlayer = (
        <div className="PlayCadre">
          <div className="linkPlay2">
            <p>Votre web-conférence commence le jeudi 28 septembre 2023, à&#160;17h30</p>
          </div>
        </div>
      );
    }
    if (this.state.validation === true) {
      var template = (
        <div>
          <div className="titleInscription titleInscription2">
            <div className="title1">INSCRIPTION </div>
          </div>

          <div className="BlueCadre">
            {templatePlayer}
            <div className="titreTeaser">
              {/** <p>Teaser video : Dr Marie MARTIN, rhumatologue</p>*/}
            </div>
          </div>
          {/*templateVideo*/}
        </div>
      );
    } else {
      var template = (
        <form>
          <div className="titleInscription">
            <div className="title1">INSCRIPTION </div>
          </div>

          <div className="flex-container2">
            <div className="inside1">
              <div className="form-group testContainer">
                <input
                  type="text"
                  className="form-control"
                  name="nom"
                  placeholder="Nom*"
                  required
                  value={this.state.nom}
                  onChange={this.handleChangeNom}
                />
                <span style={{ color: "red", fontSize: 8 }}>
                  {this.state.errors["nom"]}
                </span>
              </div>

              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email*"
                  required
                  value={this.state.email}
                  onChange={this.handleChangeEmail}
                />
                {this.state.errors["email"]}
              </div>
            </div>

            <div className="programme2 inside2">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="prenom"
                  placeholder="Prénom*"
                  required
                  value={this.state.prenom}
                  onChange={this.handleChangePrenom}
                />
                {this.state.errors["prenom"]}
              </div>

              {/*<div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="RPPS"
                  placeholder="RPPS"
                  value={this.state.rpps}
                  onChange={this.handleChangeRpps}
                />
                {this.state.errors["rpps"]}
      </div>*/}
            </div>
          </div>
          {/*<div className="form-group testContainer" style={{ display: "flex" }}>
            <label className="LabelAssiste">Je souhaite assister à :</label>
            <div
              ref={this.boutonRef}
              style={{ backgroundColor: "#ededed", flex: 0.75 }}
            >
              <div onClick={this.onBouton} className="buttonSession">
                <span className="buttonText">
                  Sélectionnez la ou les sessions de votre choix:
                </span>
                <img src={this.state.boutonOpen ? arrowD : arrowR}></img>
              </div>
              {this.state.boutonOpen ? (
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "#ededed",
                    zIndex: 100,
                    width: this.state.boutonWidth,
                  }}
                >
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.sessionTout}
                      ref="Tout"
                      onChange={this.onChangeAll}
                    />
                    <span className="labelCheck"></span>
                  </label>
                  <label className="LabelAssiste2">Toute la journée</label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.sessionEpidemio}
                      ref="Epidemio"
                      onChange={this.onChangeEpidemio}
                    />
                    <span className="labelCheck"></span>
                  </label>
                  <label className="LabelAssiste2">
                    Epidémiologie, Prévention et Impact clinique (1h20)
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.sessionRestore}
                      ref="RESTORE"
                      onChange={this.onChangeRestore}
                    />
                    <span className="labelCheck"></span>
                  </label>
                  <label className="LabelAssiste2">
                    Etudes RESTORE et sous-études (45 min)
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.sessionTableRonde}
                      ref="TableRonde"
                      onChange={this.onChangeTableRonde}
                    />
                    <span className="labelCheck"></span>
                  </label>
                  <label className="LabelAssiste2">
                    Table ronde experts sur la place dans les infections
                    pulmonaires en réanimation des nouvelles molécules (50 min)
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.sessionCoeur}
                      ref="AuCoeur"
                      onChange={this.onChangeCoeur}
                    />
                    <span className="labelCheck"></span>
                  </label>
                  <label className="LabelAssiste2">
                    Au coeur de l'action (1h)
                  </label>
                </div>
              ) : null}
            </div>
            {/*<select
              value={this.state.sessions}
              onChange={this.handleChangesessions}
            >
              <option value={""} disabled>
                Sélectionnez la ou les sessions de votre choix : *
              </option>
              <option value={"Toute la journée"}>Toute la journée</option>
              <option
                value={"Epidémiologie, Prévention et Impact clinique (1h20)"}
              >
                Epidémiologie, Prévention et Impact clinique (1h20)
              </option>
              <option
                value={"Epidémiologie, Prévention et Impact clinique (1h20)"}
              >
                Etudes RESTORE et sous-études (45 min)
              </option>

              <option
                value={"Epidémiologie, Prévention et Impact clinique (1h20)"}
              >
                Table ronde experts sur la place dans les infections pulmonaires
                en réanimation des nouvelles molécules (50 min)
              </option>

              <option
                value={"Epidémiologie, Prévention et Impact clinique (1h20)"}
              >
                Au coeur de l'action (1h)
              </option>
            </select>
            {this.state.errors["sessions"]}
          </div>*/}

          <div className="form-check-inline">
            <input
              type="radio"
              className="form-check-input"
              onClick={this.handleChangeRadioCertifie}
            />
            <label className="form-check-label">
              En cochant cette case, vous acceptez la{" "}
              <span style={{ color: "#6da537", textDecoration: "underline" }}><a
                href="https://www.biogen.fr/fr_FR/privacy-policy.html"
                target="_blank"
              >
                politique RGPD
              </a></span>{" "}
              de Biogen.*
            </label>
          </div>
          <div className="Champ">* Champs obligatoires</div>
          <div className="btnValider2" onClick={this.handleSubmit}>
            <img
              src={inscris}
              alt="btn valider"
            />
          </div>
          <div style={{ textAlign: "center", width: "100%" }}>
            <input
              type="radio"
              value={this.state.popupInteresse}
              onClick={() => this.setState({ popupInteresse: true })}
            />
            <label style={{ marginLeft: 5 }}>
              Je suis intéressé(e) mais je ne suis pas disponible à cette date.
            </label>
          </div>
          {/*<div className="btnValider3">
            <a href="https://msdj2i.com/Agenda/" target="_blank">
              {" "}
              <img
                src="https://msdj2i.com/IMG/BTN_agenda-07.png"
                alt="btn agenda"
              />
            </a>
        </div>*/}

          {/*templateVideo*/}
        </form>
      );
    }
    return (
      <div className="ContainerInscription">
        {this.popupInteresse()}
        {this.Popup()}
        <div className="FormContainer">
          <div>
            <div className="FormContainer1"></div>
            <div className="FormContainer2">{template}</div>
            <div className="FormContainer2" style={{ color: "#193a51", fontWeight: "bold", fontSize: 17, marginTop: 255 }}>
              Pour toute question technique, nous vous invitons à contacter l'agence&#160;HENRI&#160;8 :<br />
              <a href="mailto:biogen@henri8.com" target="_blank">biogen@henri8.com</a> ou 01 44 90 00 59
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InscriptionFormulaire;
