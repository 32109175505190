import React, { Component } from "react";
import "./Accueil.css";
import InscriptionFormulaire from "./InscriptionFormulaire";
import Footer from "./Footer";
import Programme from "./Programme";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: "",
      popupAlert: false,
      monToken: "",
      popupInter: false,
    };
    this.popup = this.popup.bind(this);
    this.setAlert = this.setAlert.bind(this);
  }
  componentWillMount() { }
  componentDidMount() {
    // console.log(this.props.match.params.token);

    this.setState({
      monToken: this.props.match.params.token
        ? this.props.match.params.token
        : "",
    });
  }

  setAlert(alert) {
    this.setState({
      alert: alert,
      popupAlert: true,
    });
  }

  popup() {
    if (this.state.popupAlert) {
      this.intervalAlert = setInterval(() => {
        this.setState({ popupAlert: false });
        clearInterval(this.intervalAlert);
        this.intervalAlert = false;
      }, 10000);
      return (
        <div className="alertPopup">
          <p style={{ marginTop: "1.5%", fontSize: "24px" }}>Attention</p>
          <p>{this.state.alert}</p>
        </div>
      );
    } else if (!this.state.popupAlert && !this.intervalAlert) {
      clearInterval(this.intervalAlert);
      this.intervalAlert = false;
    }
  }

  render() {
    if (this.state.monToken === undefined) {
      var template = (
        <div className="BlockErreurBlue">
          <h1>Erreur</h1>
          <p>
            Nous vous prions de revenir à cette page avec votre lien
            personnaliser{" "}
          </p>
        </div>
      );
    } else {
      var template = (
        <div>
          <div>.</div>
          {/**  <div className="titleWebconference">
            <div className="titleweb1">Web-conférence</div>
            <div className="titleweb2">
              FLASH DE LA NEURO : Lymphome (oculo) cérébral ou lésion
              inflammatoire pseudotumorale ? Comment éviter les pièges ?
            </div>
            <div className="titleweb3">
              Mardi 7 septembre 2021 de 12h45 à 14h00.
            </div>
          </div>*/}

          <div className="ContainerV2">
            <div className="ContainerV2In1">
              <Programme token={this.props.match.params.token} />
            </div>
            <div className="ContainerV2In2">
              <InscriptionFormulaire
                alert={this.setAlert}
                token={this.props.match.params.token}
              />{" "}
            </div>
          </div>
          {<Footer />}
        </div>
      );
    }

    return (
      <div>
        {this.popup()}
        {template}
      </div>
    );
  }
}

export default Header;
