import React, { Component } from "react";
import "./Prog.css";

class Prog extends Component {
  componentWillMount() { }
  componentDidMount() { }

  render() {
    return (
      <div className="ProgContainer overflow">
        <div className="ProgTite">
          <strong> Jeudi 28 septembre 2023 - </strong>
          <span className="blueText">De 17h30 à 19h30 - en distanciel <br /></span>
        </div>

        <div className="ProgTite">
          <strong>Échanges pluridisciplinaires pour le suivi des patients <span className="greenText">SMA</span></strong>
        </div>

        <ul style={{ listStyleType: "circle" }} className="BlockProg">
          <li style={{ listStyleType: "none" }}>
          <span className="blueText">17h30</span>
            <ul style={{ listStyleType: "none", marginLeft: "20px" }}>
              <li className="TitreOrateur">
              <span className="titleSpan">INTRODUCTION</span> <br />
                <span className="Experts">
                  {" "}
                  <strong>Pr Shahram Attarian</strong>, <i>Neurologue APHM - La Timone</i>
                </span>
              </li>
            </ul>
          </li>
        </ul>

        <ul style={{ listStyleType: "circle" }} className="BlockProg">
          <li style={{ listStyleType: "none" }}>
          <span className="blueText">17h35</span>
            <ul style={{ listStyleType: "none", marginLeft: "20px" }}>
              <li className="TitreOrateur">
              <span className="titleSpan">TABLE RONDE - BIOMARQUEURS</span> <br />
                <span className="Experts">
                  {" "}
                  <span className="blueText">Modérateur:</span> <strong>Dr Tanya Stojkovic</strong>, <i>Neurologue APHP - La Pitié-Salpêtrière</i><br />
                  <span className="blueText">Biomarqueur et EMG</span><strong> - Dr Pascal Cintas</strong>, <i>Neurologue CHU Toulouse</i><br />
                  <span className="blueText">Biomarqueur et Radiologie</span><strong> - Pr Robert Carlier</strong>, <i>Radiologue APHP - Raymond-Poincaré</i><br />
                  <span className="blueText">Biomarqueur et Biologie</span><strong> - Dr Tanya Stojkovic</strong>, <i>Neurologue APHP - La Pitié-Salpêtrière</i><br />
                  <span className="blueText">Discussion</span><strong> - Tous</strong><br />
                </span>
              </li>
            </ul>
          </li>
        </ul>

        <ul style={{ listStyleType: "circle" }} className="BlockProg">
          <li style={{ listStyleType: "none" }}>
          <span className="blueText">18h20</span>
            <ul style={{ listStyleType: "none", marginLeft: "20px" }}>
              <li className="TitreOrateur">
              <span className="titleSpan">TABLE RONDE - GROSSESSE ET POST PARTUM</span> <br />
                <span className="Experts">
                  {" "}
                  <span className="blueText">Modérateur: </span><strong>Dr Pascal Cintas</strong>, <i>Neurologue CHU Toulouse</i><br />
                  <span className="blueText">Vie intime et SMA</span><strong> - Mme Marie Carmen Cruz</strong>, <i>IDE CHU Toulouse</i><br />
                  <span className="blueText">Grossesse et SMA</span><strong></strong><br/>
                  <span className="blueText">Cas clinique de patientes SMA et grossesse</span><strong> - Dr Emmanuelle Salort-Campana</strong>, <i>Neurologue APHM - La Timone</i><br />
                  <span className="blueText">Discussion</span><strong> - Tous</strong><br />
                </span>
              </li>
            </ul>
          </li>
        </ul>

        <ul style={{ listStyleType: "circle" }} className="BlockProg">
          <li style={{ listStyleType: "none" }}>
          <span className="blueText">19h05</span>
            <ul style={{ listStyleType: "none", marginLeft: "20px" }}>
              <li className="TitreOrateur">
              <span className="titleSpan">SESSION ACTUALITÉS</span> <br />
                <span className="Experts">
                  {" "}
                  <span className="blueText">Actualités récentes et données de vie réelle</span><br />
                  <strong>Pr Anna Kostera-Pruszczyk</strong>, <i>Neurologue UCK Varsovie, Pologne</i><br />
                </span>
              </li>
            </ul>
          </li>
        </ul>
        <ul style={{ listStyleType: "circle" }} className="BlockProg">
          <li style={{ listStyleType: "none" }}>
          <span className="blueText">19h25</span>
            <ul style={{ listStyleType: "none", marginLeft: "20px" }}>
              <li className="TitreOrateur">
              <span className="titleSpan">CONCLUSION</span> <br />
                <span className="Experts">
                  {" "}
                  <strong>Pr Shahram Attarian</strong>, <i>Neurologue APHM - La Timone</i>
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

export default Prog;
