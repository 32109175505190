import React, { Component } from "react";
import "./Satis.css";
import starEmpty from '../../../assets/star_empty.png'
import starFull from '../../../assets/star_full.png'
import axios from "axios";

export default class Satis extends Component {
    question = [
        {
            question: "Globalement, êtes-vous satisfait(e) de cette web-conférence ?",
            reponses: ["Pas du tout satisfait", "Peu satisfait", "Assez satisfait", "Très satisfait", "Extrêmement satisfait"]
        },
        {
            question: "Qu'avez-vous pensé du contenu présenté ?",
            reponses: ["Pas du tout satisfait", "Peu satisfait", "Assez satisfait", "Très satisfait", "Extrêmement satisfait"]
        },
        {
            question: "Comment jugez-vous l'effort que vous avez dû déployer pour accéder à la web-conférence (accès, connexion...) ?",
            reponses: ["Extrêmement difficile", "Très difficile", "Assez difficile", "Sans avis", "Plutôt facile", "Très facile", "Extrêmement facile"]
        },
    ]

    constructor(props, context) {
        super(props, context);
        this.state = {
            page: 0,
            selected: -1,
            q1: -1,
            q2: -1,
            sended: false,
            received: false,
        }
        this.validate = this.validate.bind(this)
    }

    changeSelected(i) {
        this.setState({
            selected: i
        })
    }

    sendQuestions() {
        this.setState({ sended: true })
        axios.post(
            // ! Changer le lien pour le site déployé
            // `https://h8.events.novartis.fr/api/users/answers`, prod
            `https://msdj2i.herokuapp.com/users/questions`,
            {
                token: this.props.match.params.token ? this.props.match.params.token : "",
                q1: this.state.q1, q2: this.state.q2, q3: this.state.selected
            }
        ).then((res) => {
            this.setState({ received: true });
        }).catch(function (err) {
            console.log(err)
            this.setState({ sended: false })
        });
    }

    validate() {
        var state = { ...this.state };
        state["q" + (this.state.page + 1)] = this.state.selected;
        if (this.state.page + 1 < this.question.length) {
            state.page = state.page + 1;
            state.selected = -1;
            this.setState(state);
        }
        else {
            this.setState(state);
            this.sendQuestions();
        }
    }

    render() {
        if (this.state.sended) {
            var display = (
                <div style={{ textAlign: "center", fontSize: 25, marginTop: "7%" }}>
                    Envoi de votre réponse en cours,
                    Cela peux prendre une dizaine de secondes.
                </div>
            )
            if (this.state.received) {
                display = (
                    <div style={{ textAlign: "center", fontSize: 25, marginTop: "7%" }}>
                        Merci de votre réponse.
                    </div>)
            }
            return (
                <div>
                    {display}
                </div>
            )
        }
        else {
            var stars = []
            var points = []
            var i = 0;
            this.question[this.state.page].reponses.forEach((element, key) => {
                var image = starEmpty
                if (i <= this.state.selected)
                    image = starFull
                stars.push(
                    <div className={"starBlock" + this.question[this.state.page].reponses.length}>
                        <img className="star" src={image} alt={"étoile" + i <= this.state.selected ? " sélectionnée" : ""} onClick={() => this.changeSelected(key)} />
                        <div>
                            {this.question[this.state.page].reponses[i]}
                        </div>
                    </div>
                )
                i++;
            });
            this.question.forEach((element, key) => {
                var color = "RoundUnselect"
                if (key === this.state.page) {
                    color = "RoundSelect"
                }
                points.push(
                    <div className={color}>

                    </div>
                )
            })
            var button;
            if (this.state.page + 1 == this.question.length) {
                button = <div className={"validateButtonS " + (this.state.selected != -1 ? "" : "greyBack")} onClick={() => this.validate()}>ENVOYER</div>
            }
            else {
                button = <div className={"validateButtonS " + (this.state.selected != -1 ? "" : "greyBack")} onClick={() => this.validate()}>SUIVANT</div>
            }
            return (
                <div>
                    <div style={{ textAlign: 'center', fontSize: 40, marginTop: "7%" }}>
                        Merci d'avoir participé à ce live
                </div>
                    <div style={{ textAlign: 'center', fontSize: 20, color: "grey" }}>
                        Aidez-nous à améliorer votre expérience en répondant à ces quelques questions !
                </div>
                    <div style={{ textAlign: 'center', fontSize: 40, marginTop: '2%' }}>
                        QUESTION {this.state.page + 1}/{this.question.length}
                    </div>
                    <div style={{ textAlign: 'center', fontSize: 25, marginTop: '2%', marginBottom: '3%' }}>
                        {this.question[this.state.page].question}
                    </div>
                    <div style={{ width: "90%", marginLeft: 'auto', marginRight: 'auto' }}>
                        {stars}
                    </div>
                    <div style={{ width: "7%", marginLeft: "auto", marginRight: "auto", marginTop: "5%" }}>
                        {points}
                    </div>
                    <div style={{ position: "absolute", right: '5%', bottom: '10%' }}>
                        {button}
                    </div>
                </div>
            )
        }
    }
}