import React, { Component } from "react";
import "./Programme.css";
import Prog from "./Container/Prog";
import Cv from "./Container/Cv";
import Orateurs from "./Container/Orateurs";
import Quizz from "./Container/Quizz";

class Programme extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      page: "programme",
    };

    this.handleChangeToProgramme = this.handleChangeToProgramme.bind(this);
    this.handleChangeToOrateur = this.handleChangeToOrateur.bind(this);
    this.handleChangeToIntervenants = this.handleChangeToIntervenants.bind(
      this
    );
    this.handleChangeToQuestionnaires = this.handleChangeToQuestionnaires.bind(
      this
    );
  }

  handleChangeToProgramme() {
    this.setState({ page: "programme" });
  }

  handleChangeToOrateur() {
    this.setState({ page: "Orateur" });
  }

  handleChangeToIntervenants() {
    this.setState({ page: "Intervenants" });
  }

  handleChangeToQuestionnaires() {
    this.setState({ page: "Questionnaires" });
  }
  componentWillMount() { }
  componentDidMount() { }

  render() {
    var myPage2 = "";

    if (this.state.page === "programme") {
      myPage2 = (
        <div className="containerPgrogrammeCampus">
          <div className="">
            <ul className="UlClass">
              <li
                className={
                  this.state.page === "programme"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToProgramme}
              >
                <a>PROGRAMME</a>
              </li>
              <li
                className={
                  this.state.page === "Orateur"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToOrateur}
              >
                <a>
                  ORATEURS
                </a>
              </li>

              {/*<li
                className={
                  this.state.page === "Questionnaires"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToQuestionnaires}
              >
                <a>
                  PARTAGEZ VOS<br />
                  CONNAISSANCES
                </a>
              </li>
              <li
                className={
                  this.state.page === "Intervenants"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToIntervenants}
              >
                <a>
                  POSEZ <br />
                  VOS QUESTIONS
                </a>
              </li>*/}
            </ul>
          </div>
          <div className="jours3">
            <Prog />
              </div>
        </div>
      );
    } else if (this.state.page === "Intervenants") {
      myPage2 = (
        <div className="containerPgrogrammeCampus">
          <div className="">
            <ul className="UlClass">
              <li
                className={
                  this.state.page === "programme"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToProgramme}
              >
                <a>PROGRAMME</a>
              </li>

              <li
                className={
                  this.state.page === "Orateur"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToOrateur}
              >
                <a>
                  ORATEURS
                </a>
              </li>
              <li
                className={
                  this.state.page === "Questionnaires"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToQuestionnaires}
              >
                <a>
                  PARTAGEZ VOS<br />
                  CONNAISSANCES
                </a>
              </li>
              <li
                className={
                  this.state.page === "Intervenants"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToIntervenants}
              >
                <a>
                  POSEZ <br />
                  VOS QUESTIONS
                </a>
              </li>
            </ul>
          </div>
          <div className="jours3">
            <Cv />
          </div>
        </div>
      );
    } else if (this.state.page === "Orateur") {
      myPage2 = (
        <div className="containerPgrogrammeCampus">
          <div className="">
            <ul className="UlClass">
              <li
                className={
                  this.state.page === "programme"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToProgramme}
              >
                <a>PROGRAMME</a>
              </li>

              <li
                className={
                  this.state.page === "Orateur"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToOrateur}
              >
                <a>
                  ORATEURS
                </a>
              </li>
              {/*<li
                className={
                  this.state.page === "Questionnaires"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToQuestionnaires}
              >
                <a>
                  PARTAGEZ VOS<br />
                  CONNAISSANCES
                </a>
              </li>
              <li
                className={
                  this.state.page === "Intervenants"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToIntervenants}
              >
                <a>
                  POSEZ <br />
                  VOS QUESTIONS
                </a>
              </li>*/}
            </ul>
          </div>
          <div className="jours3">
            <Orateurs />
          </div>
        </div>
      );
    } else {
      myPage2 = (
        <div className="containerPgrogrammeCampus">
          <div className="">
            <ul className="UlClass">
              <li
                className={
                  this.state.page === "programme"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToProgramme}
              >
                <a>PROGRAMME</a>
              </li>

              <li
                className={
                  this.state.page === "Orateur"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToOrateur}
              >
                <a>
                  ORATEURS
                </a>
              </li>

              <li
                className={
                  this.state.page === "Questionnaires"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToQuestionnaires}
              >
                <a>
PARTAGEZ VOS<br />
                  CONNAISSANCES
                </a>
              </li>
              <li
                className={
                  this.state.page === "Intervenants"
                    ? "btnActive LIclasses"
                    : "LIclasses"
                }
                onClick={this.handleChangeToIntervenants}
              >
                <a>
                  POSEZ <br />
                  VOS QUESTIONS
                </a>
              </li>
            </ul>
          </div>
          <div className="jours3">
            <Quizz token={this.props.token} />
          </div>
        </div>
      );
    }

    return (<div>
      {myPage2}<br />
      <div className="containerPgrogrammeCampus" style={{ backgroundColor: "transparent", color: "#013a51", lineHeight: "0.95em", fontSize: 12, }}>
      </div>
    </div>);
  }
}

export default Programme;
