import React, { Component } from "react";
import "./Quizz.css";

class Quizz extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      Q1: false,
      Q2: false,
      Q3: false,
      Q4: false,
      Q5: false,

      a1: false,
      a2: false,
      a3: false,
      a4: false,

      b1: false,
      b2: false,
      b3: false,
      b4: false,

      c1: false,
      c2: false,
      c3: false,
      c4: false,

      d1: false,
      d2: false,
      d3: false,
      d4: false,

      d1: false,
      d2: false,
      d3: false,
      d4: false,
    };

    this.handleSubmit1 = this.handleSubmit1.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.handleSubmit3 = this.handleSubmit3.bind(this);
    this.handleSubmit4 = this.handleSubmit4.bind(this);

    this.handleSubmitb1 = this.handleSubmitb1.bind(this);
    this.handleSubmitb2 = this.handleSubmitb2.bind(this);
    this.handleSubmitb3 = this.handleSubmitb3.bind(this);
    this.handleSubmitb4 = this.handleSubmitb4.bind(this);

    this.handleSubmitc1 = this.handleSubmitc1.bind(this);
    this.handleSubmitc2 = this.handleSubmitc2.bind(this);
    this.handleSubmitc3 = this.handleSubmitc3.bind(this);
    this.handleSubmitc4 = this.handleSubmitc4.bind(this);

    this.handleSubmitd1 = this.handleSubmitd1.bind(this);
    this.handleSubmitd2 = this.handleSubmitd2.bind(this);
    this.handleSubmitd3 = this.handleSubmitd3.bind(this);
    this.handleSubmitd4 = this.handleSubmitd4.bind(this);

    this.handleSubmite1 = this.handleSubmite1.bind(this);
    this.handleSubmite2 = this.handleSubmite2.bind(this);
    this.handleSubmite3 = this.handleSubmite3.bind(this);
    this.handleSubmite4 = this.handleSubmite4.bind(this);
  }
  componentWillMount() {}
  componentDidMount() {}

  handleSubmit1(event) {
    this.setState({ a1: true, Q1: true });
  }
  handleSubmit2(event) {
    this.setState({ a2: true, Q1: true });
  }
  handleSubmit3(event) {
    this.setState({ a3: true, Q1: true });
  }
  handleSubmit4(event) {
    this.setState({ a4: true, Q1: true });
  }

  handleSubmitb1(event) {
    this.setState({ b1: true, Q2: true });
  }
  handleSubmitb2(event) {
    this.setState({ b2: true, Q2: true });
  }
  handleSubmitb3(event) {
    this.setState({ b3: true, Q2: true });
  }
  handleSubmitb4(event) {
    this.setState({ b4: true, Q2: true });
  }

  handleSubmitc1(event) {
    this.setState({ c1: true, Q3: true });
  }
  handleSubmitc2(event) {
    this.setState({ c2: true, Q3: true });
  }
  handleSubmitc3(event) {
    this.setState({ c3: true, Q3: true });
  }
  handleSubmitc4(event) {
    this.setState({ c4: true, Q3: true });
  }

  handleSubmitd1(event) {
    this.setState({ d1: true, Q4: true });
  }
  handleSubmitd2(event) {
    this.setState({ d2: true, Q4: true });
  }
  handleSubmitd3(event) {
    this.setState({ d3: true, Q4: true });
  }
  handleSubmitd4(event) {
    this.setState({ d4: true, Q4: true });
  }

  handleSubmite1(event) {
    this.setState({ e1: true, Q5: true });
  }
  handleSubmite2(event) {
    this.setState({ e2: true, Q5: true });
  }
  handleSubmite3(event) {
    this.setState({ e3: true, Q5: true });
  }
  handleSubmite4(event) {
    this.setState({ e4: true, Q5: true });
  }

  render() {
    return (
        <div className="QuizzContainer overflow">
      {/*<div className="QuizzContainer overflow">
        <div>
          <div className="Questipn"></div>
          <div className="Reponse">
            <label className="scales">
              Pour les 5 quiz suivants, cliquez sur la réponse de votre choix.
              La bonne réponse apparaitra en vert.
            </label>
          </div>
        </div>
        <br />
        <p>
          {" "}
          <strong>
            {" "}
            1. Les infections à bactérie multirésistante gram négatif comparé à
            une bactérie gram négatif sensible entraîne une augmentation de :
          </strong>
        </p>
        <label>
          <input
            type="checkbox"
            checked={this.state.a1}
            ref="Tout"
            onChange={this.handleSubmit1}
          />
          <span className="labelCheck"> </span>
        </label>
        <label className="LabelAssiste2">10% de surcoût hospitalier</label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.a2}
            ref="Tout"
            onClick={this.handleSubmit2}
          />
          <span className="labelCheck"> </span>
        </label>
        <label className="LabelAssiste2">20% de surcoût hospitalier</label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.a3}
            ref="Tout"
            onClick={this.handleSubmit3}
          />
          <span className="labelCheck"> </span>
        </label>
        <label
          className={
            this.state.Q1 === true ? "Green LabelAssiste2" : "LabelAssiste2"
          }
        >
          30% de surcoût hospitalier <sup>1</sup>
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.a4}
            ref="Tout"
            onClick={this.handleSubmit4}
          />
          <span className="labelCheck"> </span>
        </label>
        <label className="LabelAssiste2"> 40% de surcoût hospitalier</label>
        <br /> <br />
        <p>
          {" "}
          <strong>
            {" "}
            2. Les infections à bactérie multirésistante gram négatif comparé à
            une bactérie gram négatif sensible entraîne un allongement de la
            durée d'hospitalisation de
          </strong>
        </p>
        <label>
          <input
            type="checkbox"
            checked={this.state.b1}
            ref="Tout"
            onChange={this.handleSubmitb1}
          />
          <span className="labelCheck"> </span>
        </label>
        <label className="LabelAssiste2"> 5 à 7 jours</label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.b2}
            ref="Tout"
            onClick={this.handleSubmitb2}
          />
          <span className="labelCheck"> </span>
        </label>
        <label className="LabelAssiste2">8 à 10 jours</label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.b3}
            ref="Tout"
            onClick={this.handleSubmitb3}
          />
          <span className="labelCheck"> </span>
        </label>
        <label
          className={
            this.state.Q2 === true ? "Green LabelAssiste2" : "LabelAssiste2"
          }
        >
          11 à 13 jours <sup>1,2</sup>
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.b4}
            ref="Tout"
            onClick={this.handleSubmitb4}
          />
          <span className="labelCheck"> </span>
        </label>
        <label className="LabelAssiste2"> 14 à 16 jours</label>
        <br /> <br />
        <p>
          {" "}
          <strong>
            {" "}
            3. Quelle est la mortalité hospitalière des pneumonies associées aux
            soins liées à P. aeruginosa ?
          </strong>
        </p>
        <label>
          <input
            type="checkbox"
            checked={this.state.c1}
            ref="Tout"
            onChange={this.handleSubmitc1}
          />
          <span className="labelCheck"> </span>
        </label>
        <label className="LabelAssiste2"> 10 %</label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.c2}
            ref="Tout"
            onClick={this.handleSubmitc2}
          />
          <span className="labelCheck"> </span>
        </label>
        <label className="LabelAssiste2"> 20%</label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.c3}
            ref="Tout"
            onClick={this.handleSubmitc3}
          />
          <span className="labelCheck"> </span>
        </label>
        <label className="LabelAssiste2">30 %</label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.c4}
            ref="Tout"
            onClick={this.handleSubmitc4}
          />
          <span className="labelCheck"> </span>
        </label>
        <label
          className={
            this.state.Q3 === true ? "Green LabelAssiste2" : "LabelAssiste2"
          }
        >
          {" "}
          40 %3 <sup>1,2</sup>
        </label>
        <br /> <br />
        <p>
          {" "}
          <strong> 4. Quelle affirmation est fausse ?</strong>
        </p>
        <label>
          <input
            type="checkbox"
            checked={this.state.d1}
            ref="Tout"
            onChange={this.handleSubmitd1}
          />
          <span className="labelCheck"> </span>
        </label>
        <label className="LabelAssiste2">
          {" "}
          Le principal mécanisme de résistance aux carbapénèmes de Pseudomonas
          aeruginosa se fait par imperméabilité liée à la perte de porine
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.d2}
            ref="Tout"
            onClick={this.handleSubmitd2}
          />
          <span className="labelCheck"> </span>
        </label>
        <label
          className={
            this.state.Q4 === true ? "Green LabelAssiste2" : "LabelAssiste2"
          }
        >
          {" "}
          La carbapénèmase la plus rencontrée en France est KPC
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.d3}
            ref="Tout"
            onClick={this.handleSubmitd3}
          />
          <span className="labelCheck"> </span>
        </label>
        <label className="LabelAssiste2">
          La carbapénèmase la plus rencontrée en France est OXA-48
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.d4}
            ref="Tout"
            onClick={this.handleSubmitd4}
          />
          <span className="labelCheck"> </span>
        </label>
        <label className="LabelAssiste2">
          {" "}
          L'antibiorésistance, un phénomène responsable de 5500 décès par an, en
          France
        </label>
        <br /> <br />
        <p>
          {" "}
          <strong>
            {" "}
            5. Parmi les K. Pneumoniae identifiées sur des prélèvements
            cliniques invasifs issus du milieu hospitalier en France en 2019 et
            2020, combien sont producteurs d’une carbapénémase ?
          </strong>
        </p>
        <label>
          <input
            type="checkbox"
            checked={this.state.e1}
            ref="Tout"
            onChange={this.handleSubmite1}
          />
          <span className="labelCheck"> </span>
        </label>
        <label className="LabelAssiste2"> Moins de 0.1%</label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.e2}
            ref="Tout"
            onClick={this.handleSubmite2}
          />
          <span className="labelCheck"> </span>
        </label>
        <label
          className={
            this.state.Q5 === true ? "Green LabelAssiste2" : "LabelAssiste2"
          }
        >
          {" "}
          0,5% à 1%4
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.e3}
            ref="Tout"
            onClick={this.handleSubmite3}
          />
          <span className="labelCheck"> </span>
        </label>
        <label className="LabelAssiste2">10%</label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.e4}
            ref="Tout"
            onClick={this.handleSubmite4}
          />
          <span className="labelCheck"> </span>
        </label>
        <label className="LabelAssiste2"> Plus de 10%</label>
        <br />
        <div className="sources">
          Sources :
          <ul>
            <li>
              1. Mauldin PD. et al. Attributable Hospital Cost and Lenght of
              Stay Associated with Health Care-Associated Infections Caused by
              Antibiotic-Resistant Gram-Negative Bacteria. Antimicrobial agents
              and chemotherapy 2010;54 : 109-15
            </li>
            <li>
              2. Kollef et al. Economic Impact of Ventilator-Associated
              Pneumonia in a Large Matched Cohort. Infect Control Hosp Epidemiol
              2012; 33 : 250-6
            </li>
            <li>
              3. Micek ST et al. An international multicenter retrospective
              study of Pseudomonas aeruginosa nosocomial pneumonia : impact of
              multidrug resistance. Critical Care 2015;19 :1-8
            </li>
            <li>
              4. ECDC. Surveillance atlas of infectious diseases.
              https://atlas.ecdc.europa.eu/public/index.aspx
            </li>
            <li>
              5. HCSP. Actualisation des recommandations relatives aux BHRe,
              2019.
              https://www.hcsp.fr/explore.cgi/avisrapportsdomaine?clefr=758
            </li>
          </ul>
        </div>
        </div>*/}
          
        </div>
    );
  }
}

export default Quizz;
