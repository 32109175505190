import React, { Component } from "react";
import "./Cv.css";
import { Firebase_Config } from "../../../Config/config";
import * as firebase from "firebase/app";
import "firebase/database";

class Cv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      etat: false,
      envoye: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }
  }
  componentWillMount() {
    this.db = firebase.database();
  }
  componentDidMount() {}
  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    if (this.state.value !== "") {
      let uid = firebase.database().ref().child("questionsMSDJ2I").push().key;
      let uid2 = firebase.database().ref().child("questionsMSDJ2I").push().key;

      let d = new Date();
      let n = d.toLocaleString();
      let data = {
        id: uid,
        question: this.state.value,
        date: n,
      };

      let updates = {};
      let updates2 = {};

      updates["/questionsEPSMA/" + uid] = data;
      firebase
        .database()
        .ref()
        .update(updates)
        .then(
          this.setState({
            etat: false,
            value: "",
            envoye: true,
          }),
          setTimeout(() => {
            this.setState({
              envoye: false,
            });
          }, 5000)
        );

      updates2["/questionsEPSMASave/" + uid] = data;
      firebase.database().ref().update(updates2);

      event.preventDefault();
    }
  }

  render() {
    var templateAlerte = "";

    if (this.state.envoye == true) {
      templateAlerte = (
        <div>
          <div className="AlertQuestion">
            {" "}
            <div>Votre question a bien été envoyée</div>
          </div>
        </div>
      );
    } else {
      templateAlerte = "";
    }
    return (
      <div className="CvContainer overflow">
        <form onSubmit={this.handleSubmit} className="FormContainer">
          <label style={{width:"100%"}}>
            <textarea
              value={this.state.value}
              onChange={this.handleChange}
              placeholder=""
            />
          </label>
          <br></br>
          <input type="submit" value="ENVOYER" className="btnValider" />
        </form>
        {templateAlerte}
      </div>
    );
  }
}

export default Cv;
