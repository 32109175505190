import React, { Component } from "react";
import "./Footer.css";

class Footer extends Component {
  componentWillMount() { }
  componentDidMount() { }

  render() {
    return (
      <div className="ShareFooter">
        <div className="FooterContainer">
          <div className="FooterLinkContainer">
            <a className="footerLink" href="https://biogenlinc.fr/contact" target="_blank">Contact</a>
            <a className="footerLink linkMargin" href="https://www.biogen.fr/fr_FR/terms-conditions.html" target="_blank">Conditions Générales d’Utilisation</a>
            <a className="footerLink linkMargin" href="https://www.biogen.fr/fr_FR/privacy-policy.html" target="_blank">Politique de Confidentialité</a>
            <a className="footerLink linkMargin" href="https://www.biogen.fr/fr_FR/contactus.html" target="_blank">Pharmacovigilance</a>
          </div>
          <div className="FooterText">
            BIOGEN France SAS<br />
            Tour CBX<br />
            1, Passerelle des Reflets<br />
            92913 Paris La Défense Cedex<br />
            398 410 126 RCS Nanterre<br /><br />
            Vous pouvez trouver des informations complémentaires concernant le traitement de vos données personnelles en allant sur <a style={{ color: "#007bff" }} href="https://www.biogen.fr/fr_FR/privacy-policy.html" target="_blank">https://www.biogen.fr/fr_FR/privacy-policy.html</a> et en cliquant sur <a style={{ color: "#007bff" }} href="https://www.biogen.fr/fr_FR/privacy-policy.html" target="_blank">« Politique de confidentialité – Professionnels de santé »</a>.<br /><br />
            ©2023 Biogen France – Dernière date de mise à jour: 07/2023 - Biogen-216325
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
